import { post, get } from './common'
import { API_BASE_URL } from '@/constants/setting'

const getTwitter = async () => {
  return await get('sns/twitter', true)
}

const getYoutubes = async () => {
  return await get('sns/youtubes', true)
}

const getBlogs = async () => {
  return await get('sns/blogs', true)
}

const getFacebook = async () => {
  return await get('sns/facebook', true)
}

export { getTwitter, getYoutubes, getBlogs, getFacebook }
