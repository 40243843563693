<template>
  <div id="header">
    <div class="header_in container flex items-center">
      <img src="@/assets/icon/logo.svg" class="w-[40px] h-[40px] mr-[10px]" />
      <h1 class="text-title-36 text-sk-gradient relative">
        <router-link to="/"
          ><p class="animate-blink text-sk-gradient">이원욱</p></router-link
        >
        <router-link to="/"
          ><p
            class="w-[132px] text-sk-gradient absolute top-0 left-0 opacity-0 animate-blink2"
          >
            AI 대변인
          </p>
        </router-link>
      </h1>
      <div @click="openTrue" class="sitemap">
        <span></span>
        <span class="middle"></span>
        <span></span>
      </div>
    </div>
    <div class="menu xl:block hidden">
      <ul>
        <li class="one">
          <router-link to="/intro/greeting">소개</router-link>
          <dl>
            <dd><router-link to="/intro/greeting">인사말</router-link></dd>
            <dd><router-link to="/intro/history">걸어온 길</router-link></dd>
            <dd><router-link to="/intro/sponsor">후원 안내</router-link></dd>
          </dl>
        </li>
        <li class="two">
          <router-link to="/archive/policy">자료실</router-link>
          <dl>
            <dd><router-link to="/archive/policy">정책자료</router-link></dd>
            <dd><router-link to="/archive/promise">공약자료</router-link></dd>
            <dd>
              <a
                target="_blank"
                href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=73"
                >언론이 본 이원욱</a
              >
            </dd>
          </dl>
        </li>
        <!-- <li class="three">
          <a target="_blank" href="https://youtube.com/@TV-mu6rk">콘텐츠</a>
          <dl>
            <dd><router-link to="/">사진 콘텐츠</router-link></dd>
            <dd><router-link to="/">영상 콘텐츠</router-link></dd>
          </dl>
        </li> -->
        <li class="four">
          <a href="#">이원욱의 의정활동</a>
          <dl>
            <dd>
              <a
                target="_blank"
                href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=84&parentCategoryNo=84"
                >국회활동</a
              >
            </dd>
            <dd>
              <a
                target="_blank"
                href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=81&parentCategoryNo=81"
                >화성 지역활동</a
              >
            </dd>
          </dl>
        </li>
        <!-- <li class="five">
          <a
            target="_blank"
            href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=81&parentCategoryNo=81"
            >화성 지역활동</a
          >
          <dl></dl>
        </li> -->
        <!-- <li class="six">
          
          <dl></dl>
        </li> -->
        <li class="seven">
          <a href="#" @click="ready">이원욱 응답센터</a>
          <dl>
            <dd><a href="#" @click="ready">건의합니다</a></dd>
            <dd><a href="#" @click="ready">답합니다</a></dd>
          </dl>
        </li>
        <li class="eight">
          <router-link to="/chat">AI 대변인과 대화하기</router-link>
          <dl></dl>
        </li>
      </ul>
      <div class="bg"></div>
    </div>
    <div
      x-description="Mobile menu, show/hide based on menu open state."
      class="xl:hidden"
      v-show="openInfo.open"
      aria-modal="true"
    >
      <div
        x-description="Background backdrop, show/hide based on slide-over state."
        class="fixed inset-0 z-10"
        @click="openFalse"
      ></div>
      <div
        class="fixed inset-y-0 right-0 z-[9999] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <div></div>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700"
            @click="openFalse"
          >
            <span class="sr-only">Close menu</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <div class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu1"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  소개
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu1 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu1"
                >
                  <router-link
                    to="/intro/greeting"
                    @click="openFalse"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >인사말
                  </router-link>
                  <router-link
                    to="/intro/history"
                    @click="openFalse"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >걸어온 길
                  </router-link>
                  <router-link
                    to="/intro/sponsor"
                    @click="openFalse"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >후원 안내
                  </router-link>
                </div>
              </div>
              <div x-data="{ openMenu2: false }" class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu2"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  자료실
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu2 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu2"
                >
                  <router-link
                    to="/archive/policy"
                    @click="openFalse"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >정책자료
                  </router-link>
                  <router-link
                    to="/archive/promise"
                    @click="openFalse"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >공약자료
                  </router-link>
                  <a
                    target="_blank"
                    @click="openFalse"
                    href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=73"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >언론이 본 이원욱</a
                  >
                </div>
              </div>
              <!-- <div x-data="{ openMenu3: false }" class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu3"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  콘텐츠
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu3 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu3"
                >
                  <router-link
                    to="/"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >사진 콘텐츠</router-link
                  >
                  <router-link
                    to="/"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >영상 콘텐츠</router-link
                  >
                </div>
              </div> -->
              <!-- <div class="-mx-3">
                <a href="https://youtube.com/@TV-mu6rk" target="_blank" @click="openFalse">
                  <button
                    type="button"
                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  >
                    콘텐츠
                  </button>
                </a>
              </div> -->

              <div class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu3"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  이원욱의 의정활동
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu3 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu3"
                >
                  <a
                    href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=84&parentCategoryNo=84"
                    target="_blank"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    @click="openFalse"
                  >
                    국회 활동
                  </a>
                  <a
                    href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=81&parentCategoryNo=81"
                    target="_blank"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    @click="openFalse"
                  >
                    화성 지역활동
                  </a>
                </div>
              </div>
              <!-- <div class="-mx-3">
                <a
                  href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=81&parentCategoryNo=81"
                  target="_blank"
                  @click="openFalse"
                >
                  <button
                    type="button"
                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  >
                    화성 지역활동
                  </button>
                </a>
              </div> -->
              <!-- <div class="-mx-3">
                <router-link to="/intro/sponsor" @click="openFalse">
                  <button
                    type="button"
                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  >
                    후원
                  </button>
                </router-link>
              </div> -->
              <div x-data="{ openMenu4: false }" class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu4"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  이원욱 응답센터
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu4 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu4"
                >
                  <a
                    href="#"
                    @click="ready"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >건의합니다</a
                  >
                  <a
                    href="#"
                    @click="ready"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-sk-default hover:bg-sk-box001"
                    >답합니다</a
                  >
                </div>
              </div>
              <div class="-mx-3">
                <router-link to="/chat" @click="openFalse">
                  <button
                    type="button"
                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-sk-primary hover:bg-sk-gradient hover:text-sk-default2 transition-all"
                  >
                    AI 대변인과 대화하기
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {};
  },
  computed: {
    openInfo() {
      return this.$store.state.openInfo;
    },
  },
  methods: {
    openTrue() {
      let info = this.openInfo;
      info.open = true;
      this.$store.commit('updateOpenInfo', info);
    },
    openFalse() {
      let info = this.openInfo;
      info.open = false;
      this.$store.commit('updateOpenInfo', info);
    },
    changeOpenMenu1() {
      let info = this.openInfo;
      info.openMenu1 = !info.openMenu1;
      this.$store.commit('updateOpenInfo', info);
    },
    changeOpenMenu2() {
      let info = this.openInfo;
      info.openMenu2 = !info.openMenu2;
      this.$store.commit('updateOpenInfo', info);
    },
    changeOpenMenu3() {
      let info = this.openInfo;
      info.openMenu3 = !info.openMenu3;
      this.$store.commit('updateOpenInfo', info);
    },
    changeOpenMenu4() {
      let info = this.openInfo;
      info.openMenu4 = !info.openMenu4;
      this.$store.commit('updateOpenInfo', info);
    },
    ready() {
      alert('준비중입니다.');
    },
  },
};
</script>
<style scoped>
#header {
  background: #fff;
  border-bottom: 1px solid #ddd;
}

#header .header_in {
  margin: 0 auto;
  padding: 19px 12px;
  position: relative;
}
.header_in h1 {
  position: relative;
  z-index: 50;
  width: 92px;
}

#header .menu {
  position: Absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}
.menu ul {
  overflow: hidden;
  clear: both; /*width:560px;*/
  position: absolute;
  right: 10%;
  top: 30px;
  z-index: 99;
}
.menu li {
  float: left;
  position: relative;
}
.menu li > a {
  @apply text-h3-semibold pl-[15px];
  /* font-size: 18px;
  color: #3a3a3a;
  font-weight: 600;
  padding: 0 0 0 15px;
  line-height: 120%; */
  transition: color 0.3s ease;
}
.menu li > a:before {
  content: '';
  display: block;
  width: 0;
  height: 4px;
  background: linear-gradient(90deg, #ed6d00 0%, #ff7210 50%, #ea5515 100%);
  position: Absolute;
  left: 0;
  top: 55px;
  transition: all 0.3s ease;
}
.menu dl {
  padding-left: 15px;
  margin-top: 50px;
  height: 0;
  transition: all 0s ease;
  overflow: hidden;
}
.menu dd a {
  @apply text-body1-medium;
  margin-bottom: 11px;
  display: block;
  transition: all 0.3s ease-out;
}
.menu dd:last-child a {
  margin-bottom: 0;
}

.menu li.one {
  width: 115px;
}
.menu li.two {
  width: 145px;
}
.menu li.three {
  width: 110px;
}
.menu li.four {
  width: 210px;
}
.menu li.five {
  width: 140px;
}
.menu li.six {
  width: 70px;
}
.menu li.seven {
  width: 180px;
}
.menu li.eight {
  width: 210px;
  color: #ff7210;
  @apply animate-pulse;
}

.menu .bg {
  position: absolute;
  top: 88px;
  width: 100%;
  height: 0;
  background: #ffffffde;
  transition: all 0.2s ease;
  border: none;
  z-index: 20;
}
.menu ul:hover + .bg {
  height: 240px;
  transition: all 0.3s ease;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.menu ul:hover dl {
  height: 240px;
  transition: all 0.4s ease;
}
.menu li:hover > a:before {
  width: 100%;
  transition: all 0.3s ease;
}
.menu li:hover > a {
  color: #ff8d3b;
  transition: color 0.3s ease;
}
.menu dd a:hover {
  color: #ff8d3b;
  font-weight: 600;
  transition: all 0.3s ease-out;
}
.header_in .sitemap {
  display: block;
  width: 24px;
  height: 19px;
  position: absolute;
  right: 16px;
  top: 35px;
  overflow: hidden;
  clear: both;
  z-index: 50;
}
@media (min-width: 1280px) {
  .header_in .sitemap {
    display: none;
  }
}
.header_in .sitemap span {
  display: block;
  width: 100%;
  height: 3px;
  background: #707070;
  float: right;
  transition: all 0.2s ease;
}
.header_in .sitemap span.middle {
  margin: 5px 0;
  transition: all 0.2s ease;
}
.header_in .sitemap:hover span {
  background: #ff7210;
  transition: all 0.2s ease;
}
.header_in .sitemap:hover span.middle {
  width: 80%;
  transition: all 0.2s ease;
}
</style>
