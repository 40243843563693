<template>
  <div v-if="isMounted == true" class="home">
    <div
      class="fixed right-[90px] bottom-[60px] md:flex hidden flex-col gap-[20px] text-center text-body1-semibold"
    >
      <div
        class="absolute w-[100px] h-[100px] z-40 rounded-full bg-sk-primary003 animate-ping-sm"
      ></div>
      <div
        @click="this.$router.push('/chat')"
        class="w-[100px] h-[100px] z-40 rounded-full shadow-xl flex items-center justify-center text-sk-default2 cursor-pointer bg-sk-primary003 hover:bg-sk-gradient"
      >
        <p>AI대변인과<br />대화하기</p>
      </div>
      <div
        @click="ready"
        class="w-[100px] h-[100px] z-40 rounded-full shadow-xl flex items-center justify-center cursor-pointer bg-sk-box002 hover:bg-sk-box003"
      >
        <p>이원욱<br />응답센터</p>
      </div>
      <div
        @click="this.$router.push('/intro/sponsor')"
        class="w-[100px] h-[100px] z-40 rounded-full shadow-xl flex items-center justify-center cursor-pointer bg-sk-box002 hover:bg-sk-box003"
      >
        <p>후원하기</p>
      </div>
    </div>
    <img class="w-full" src="@/assets/banner.png" />
    <div class="w-full flex flex-col xl:flex-row text-sk-default2">
      <div
        class="w-full xl:w-1/2 h-[160px] bg-sk-primary002 py-[20px] flex flex-col gap-[11px]"
      >
        <div class="md:w-[712px] ml-auto px-[20px]">
          <p class="text-h2-bold">이원욱 의원 SNS 바로가기</p>
        </div>
        <div
          class="flex gap-[10px] md:gap-[40px] md:justify-start justify-between w-full sm:w-[580px] sm:ml-auto px-[20px]"
        >
          <a target="_blank" href="https://blog.naver.com/ewon33">
            <div
              class="flex flex-col items-center text-body3-medium text-sk-border"
            >
              <img src="@/assets/icon/blog_white.svg" alt="" />
              <p>블로그</p>
            </div>
          </a>
          <a target="_blank" href="https://twitter.com/ewon33">
            <div
              class="flex flex-col items-center text-body3-medium text-sk-border"
            >
              <img src="@/assets/icon/x_white.svg" alt="" />
              <p>트위터</p>
            </div>
          </a>
          <a target="_blank" href="https://www.instagram.com/ewon33/">
            <div
              class="flex flex-col items-center text-body3-medium text-sk-border"
            >
              <img src="@/assets/icon/instagram_white.svg" alt="" />
              <p>인스타그램</p>
            </div>
          </a>
          <a target="_blank" href="https://www.facebook.com/leewonwook">
            <div
              class="flex flex-col items-center text-body3-medium text-sk-border"
            >
              <img src="@/assets/icon/facebook_white.svg" alt="" />
              <p>페이스북</p>
            </div>
          </a>
          <a target="_blank" href="https://youtube.com/@TV-mu6rk">
            <div
              class="flex flex-col items-center text-body3-medium text-sk-border"
            >
              <img src="@/assets/icon/youtube_white.svg" alt="" />
              <p>유튜브</p>
            </div>
          </a>
        </div>
      </div>
      <div class="w-full xl:w-1/2 h-[160px] bg-sk-primary">
        <div
          class="h-full pl-[20px] md:pl-[80px] flex items-center md:justify-start justify-between gap-[20px] md:gap-[65px] text-h2-bold md:text-title-36"
        >
          <p class="break-words">AI 대변인이 <br />이원욱을 알려드립니다</p>
          <router-link to="/chat">
            <button
              class="mr-[20px] pl-[30px] pr-[20px] py-[13px] bg-sk-primary hover:bg-sk-box002 hover:text-sk-default focus:text-sk-default focus:bg-sk-box003 text-sk-default2 text-body2-medium border border-sk-primaryOn"
            >
              <div class="flex gap-[20px] items-center">
                <p class="truncate">더보기</p>
                <img src="@/assets/icon/arrow_right_white.svg" />
              </div>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="container mx-auto px-[10px] grid grid-cols-1 2xl:grid-cols-2 2xl:grid-rows-3 gap-x-[60px] gap-y-[100px] mt-[100px]"
    >
      <!-- ********************************************** 이원욱의 말 ********************************************** -->
      <div class="flex flex-col 2xl:row-span-1">
        <p class="text-h1-bold sm:text-title-36 mb-[30px]">이원욱의 말</p>
        <div
          class="facebook-box w-full flex justify-center"
          v-html="facebook"
        ></div>
        <!-- <div
          class="twitter-box border-2 border-sk-border rounded-[30px] p-[20px_40px_40px] mt-[28px] text-body1-semibold text-sk-sub"
        >
          <div class="flex gap-[20px] items-center justify-between mb-[20px]">
            <a target="_blank" href="https://twitter.com/ewon33"
              ><img src="@/assets/content/x_ewon33.png" alt=""
            /></a>
            <div class="w-[40px] h-[40px]">
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path
                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
          <div v-html="twitter"></div>
        </div> -->
      </div>

      <!-- ********************************************** 영상 뉴스 ********************************************** -->
      <div class="flex flex-col 2xl:row-span-2">
        <div class="flex justify-between items-center">
          <p class="text-h1-bold sm:text-title-36">영상 뉴스</p>
          <a target="_blank" href="https://youtube.com/@TV-mu6rk">
            <button
              class="px-[30px] py-[13px] bg-sk-box002 hover:bg-sk-box001 focus:bg-sk-box003 text-sk-sub text-body2-medium border border-sk-border"
            >
              <div class="flex gap-[20px] items-center">
                <p class="truncate">더보기</p>
                <img src="@/assets/icon/arrow_right_gray.svg" />
              </div>
            </button>
          </a>
        </div>
        <div class="mt-[30px] grid grid-cols-2 gap-x-[30px] gap-y-[50px]">
          <template v-for="(video, idx) in videoInfos" :key="idx">
            <div class="flex flex-col gap-[12px]">
              <div class="bg-cover bg-center">
                <div class="iframe">
                  <iframe
                    width="1600"
                    height="900"
                    :src="
                      'https://www.youtube.com/embed/' +
                      video.Url +
                      '?modestbranding=1'
                    "
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <p class="text-h3-semibold sm:text-h2-bold truncate">
                  {{ video.title }}
                </p>
                <p
                  class="text-body2-medium text-sk-info w-[90px] text-right shrink-0"
                >
                  {{ video.createdAt.split('T')[0] }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- ********************************************** 언론이 본 이원욱 ********************************************** -->
      <div class="flex flex-col 2xl:row-span-2">
        <div class="flex justify-between items-center">
          <p class="text-h1-bold sm:text-title-36">언론이 본 이원욱</p>
          <button
            class="px-[30px] py-[13px] bg-sk-box002 hover:bg-sk-box001 focus:bg-sk-box003 text-sk-sub text-body2-medium border border-sk-border"
          >
            <a
              target="_blank"
              href="https://blog.naver.com/PostList.naver?blogId=ewon33&from=postList&categoryNo=73"
            >
              <div class="flex gap-[20px] items-center">
                <p class="truncate">더보기</p>
                <img src="@/assets/icon/arrow_right_gray.svg" />
              </div>
            </a>
          </button>
        </div>
        <div class="mt-[30px] border border-sk-border">
          <a :href="photoInfos[photoIdx % 5].blogUrl" target="_blank">
            <img
              :src="photoInfos[photoIdx % 5].imgUrl"
              :alt="photoInfos[photoIdx % 5].title"
              class="cursor-pointer w-full"
            />
          </a>
          <div class="flex flex-col mt-[40px] px-[20px]">
            <a :href="photoInfos[photoIdx % 5].blogUrl" target="_blank"
              ><p class="text-h3-bold cursor-pointer">
                {{ photoInfos[photoIdx % 5].title }}
              </p></a
            >
            <p class="text-body2-medium mt-[6px] text-sk-info">
              {{ photoInfos[photoIdx % 5].createdAt.split('T')[0] }}
            </p>
            <p
              class="text-body2-medium h-[76px] mb-[16px] text-sk-sub mt-[20px] ellipsis-3"
            >
              {{ photoInfos[photoIdx % 5].contents }}
            </p>
          </div>
        </div>
        <div class="mt-[12px] flex justify-between gap-[18px]">
          <template v-for="(img, idx) in photoInfos" :key="idx">
            <div
              v-if="idx !== photoIdx % 5"
              @click="photoIdx = idx"
              class="w-full h-[13vw] 2xl:h-[100px] border border-sk-border cursor-pointer bg-cover bg-center"
              :style="{ 'background-image': 'url(' + img.imgUrl + ')' }"
            ></div>
          </template>
        </div>
        <div class="mt-[22px] ml-auto flex gap-[20px]">
          <div @click="photoIdx--" class="cursor-pointer">
            <img src="@/assets/icon/to_left.svg" />
          </div>
          <div @click="photoIdx++" class="cursor-pointer">
            <img src="@/assets/icon/to_right.svg" />
          </div>
        </div>
      </div>
      <!-- ********************************************** 후원안내 ********************************************** -->
      <div class="mt-auto 2xl:row-span-1">
        <!-- <p class="text-h1-bold sm:text-title-36">후원 안내</p>
        <p class="text-h3-regular text-sk-info mt-[13px]">
          후원 후 국회사무실로 연락주시면 정치자금영수증을 발급해 드립니다. 10만원까지 세액공제,
          초과는 소득공제 됩니다.
        </p> -->
        <div
          class="py-[20px] sm:py-[35px] px-[15px] sm:px-[40px] border-[3px] border-sk-border"
        >
          <div
            class="flex md:flex-row flex-col justify-between md:items-center gap-[20px] md:gap-0"
          >
            <div class="flex flex-col max-w-[364px]">
              <p class="text-body1-semibold">후원 안내</p>
              <p class="text-body3-medium text-sk-info mt-[9px]">
                후원 후 국회사무실로 연락주시면 정치자금영수증을 발급해드립니다.
                10만원까지 세액공제 초과는 소득공제가 가능합니다
              </p>
              <p class="text-body4-medium text-sk-info mt-[16px]">
                연락처 : 02-6788-7026
              </p>
            </div>
            <div class="flex flex-col justify-center gap-[13px]">
              <div class="flex items-baseline gap-[15px]">
                <p class="text-body3-semibold w-[37px] text-sk-info">농협</p>
                <p class="text-body1-semibold">351-0417-7091-03</p>
              </div>
              <div class="flex items-baseline gap-[15px]">
                <p class="text-body3-semibold w-[37px] text-sk-info">예금주</p>
                <p class="text-body1-semibold">국회의원 이원욱 후원회</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTwitter, getYoutubes, getBlogs, getFacebook } from '@/apis/snsApi';
export default {
  name: 'HomeView',
  data() {
    return {
      twitter: undefined,
      facebook: undefined,
      photoIdx: 0,
      isMounted: false,
      photoInfos: [
        {
          imgUrl: require('@/assets/content/pic1.png'),
          title: '원칙과상식 민심소통 1 - ‘안병진 교수에게 듣는다’',
          createdAt: '2023-12-11',
          blogUrl: 'https://www.youtube.com/@TV-mu6rk',
          contents:
            '민주당 혁신파 모임인 <원칙과상식> 은 매 주 일요일마다 민심소통 간담회를 진행하며 민주당과 한국정치의 문제점을 진단하고 있다. 첫 시간에는 청년 정치인들로부터 민주당의 전반적인 문제점을 청취했으며 두 번째 시간에는 조기숙 교수, 채진원 교수를 통해 민주당의 팬덤정치 폐해, 민주주의 퇴행, 리더십의 문제점을 짚었다.',
        },
      ],
      videoInfos: [
        {
          Url: 'a45LaYuxKEY',
          title: '제목입니다',
          createdAt: '2023-12-11',
        },
      ],
    };
  },
  async mounted() {
    // const twitterRes = await getTwitter()
    // this.twitter = twitterRes.twitter
    const facebookRes = await getFacebook();
    this.facebook = facebookRes.facebook;
    const youtubeRes = await getYoutubes();
    this.videoInfos = youtubeRes.youtubes;
    // if videoInfos has more than 4 videos, use only 4 videos
    if (this.videoInfos.length > 6) {
      this.videoInfos = this.videoInfos.slice(0, 6);
    }
    const blogRes = await getBlogs();
    this.photoInfos = blogRes.blogs;
    this.isMounted = true;
  },
  methods: {
    ready() {
      alert('준비중입니다.');
    },
  },
};
</script>
