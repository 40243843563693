import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/intro/greeting',
    name: 'intro-greeting',
    component: () =>
      import(
        /* webpackChunkName: "intro" */ '@/views/introViews/GreetingView.vue'
      ),
  },
  {
    path: '/intro/history',
    name: 'intro-history',
    component: () =>
      import(
        /* webpackChunkName: "intro" */ '@/views/introViews/HistoryView.vue'
      ),
  },
  {
    path: '/intro/sponsor',
    name: 'intro-sponsor',
    component: () =>
      import(
        /* webpackChunkName: "intro" */ '@/views/introViews/SponsorView.vue'
      ),
  },
  {
    path: '/chat',
    name: 'chat',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/chatViews/ChatView.vue'),
  },

  {
    path: '/archive/policy',
    name: 'archive-policy',
    component: () =>
      import(
        /* webpackChunkName: "archive" */ '@/views/archiveViews/PolicyView.vue'
      ),
  },
  {
    path: '/archive/promise',
    name: 'archive-promise',
    component: () =>
      import(
        /* webpackChunkName: "archive" */ '@/views/archiveViews/PromiseView.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' };
  },
});

export default router;
