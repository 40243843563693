<template>
  <div class="w-full mt-[120px]">
    <div class="bg-sk-icon h-[230px]">
      <div class="flex flex-col items-center pt-[40px]">
        <p class="text-sk-gradient text-h1-bold">이원욱</p>
        <p class="text-body2-medium text-sk-default2 mt-[8px]">
          서울시 영등포구 여의도동 1번지 국회의원회관 841호
        </p>
        <p class="text-body2-medium text-sk-default2 pt-2">
          <span class="text-sk-disabled">전화</span> 02-784-6471 |
          <span class="text-sk-disabled">팩스</span> 02-6788-7030
        </p>
        <div class="flex gap-[15px] mt-[20px]">
          <a target="_blank" href="https://blog.naver.com/ewon33"
            ><img src="@/assets/icon/blog.svg"
          /></a>
          <a target="_blank" href="https://twitter.com/ewon33"><img src="@/assets/icon/x.svg" /></a>
          <a target="_blank" href="https://www.instagram.com/ewon33/"
            ><img src="@/assets/icon/instagram.svg"
          /></a>
          <a target="_blank" href="https://www.facebook.com/leewonwook"
            ><img src="@/assets/icon/facebook.svg"
          /></a>
          <a target="_blank" href="https://youtube.com/@TV-mu6rk"
            ><img src="@/assets/icon/youtube.svg"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
